import { render, staticRenderFns } from "./OrganismSpace.vue?vue&type=template&id=4cc13118&"
import script from "./OrganismSpace.vue?vue&type=script&lang=js&"
export * from "./OrganismSpace.vue?vue&type=script&lang=js&"
import style0 from "./OrganismSpace.vue?vue&type=style&index=0&id=4cc13118&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismSpace.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fproject%2Fbuilding%2FOrganismSpace.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectMoleculeFitoutSelector: require('/app/components/molecules/project/MoleculeFitoutSelector.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,MoleculesProjectBuildingMoleculeSpaceDetailsOnSpacePage: require('/app/components/molecules/project/building/MoleculeSpaceDetailsOnSpacePage.vue').default,AtomsCommonAtomTitle: require('/app/components/atoms/common/AtomTitle.vue').default,MoleculesProjectBuildingMoleculeVideoSlider3DTour: require('/app/components/molecules/project/building/MoleculeVideoSlider3DTour.vue').default,MoleculesProjectBuildingMoleculeImageSlider3DTour: require('/app/components/molecules/project/building/MoleculeImageSlider3DTour.vue').default})
