
import { CurrentView, FloorViewMode } from '~/store/building/-constants'
import projectConstants from '~/store/project/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'
import modalConstants from '~/store/modal/-constants'
import spaceConstants from '~/store/space/-constants'
import { isMobile, isTablet } from '~/helpers/mobile/DeviceType'

export default {
  name: 'OrganismSpace',
  props: {
    matterportSpace: {
      required: true,
      type: [Object, Array],
      default: function () {
        return {
          titleSpace: '',
          subTitleSpace: ''
        }
      }
    },
    disclaimer: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viewModes: FloorViewMode,
      zoomSettings: {
        zoomValue: 0,
        minValue: 0,
        maxValue: 100
      },
      fitoutData: [],
      isSpaceSelectionOpen: false,
      activeTab: 'matterport',
      showCartSpacesMobile: !isMobile(),
    }
  },
  computed: {
    isMobileDevice() {
      return isMobile() || isTablet();
    },
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    hasMatterport() {
      return this.generalConfig.matterportEnabled
    },
    isFloorView() {
      return this.$store.state.building.currentView === CurrentView.FLOOR
    },
    viewMode() {
      return this.$store.state.building.floorViewMode
    },
    currentSpace() {
      return this.$store.state.building.space?.spaceData || {}
    },
    spaceBuilding() {
      return this.$store.state.project.project.buildings.find(
        (b) => b.id === this.currentSpace.building_id
      )
    },
    spaceFloor() {
      return this.$store.state.project.project.floors.find(
        (f) => f.id === this.currentSpace.floor_id
      )
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    unitOfMeasure() {
      return this.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    isDualLevel() {
      return this.currentSpace.is_dual_level
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    gallery() {
      return this.$store.state.space.gallery
    },
    photos() {
      return JSON.parse(JSON.stringify(this.$store.state.space?.photos || []))
    },
    videos() {
      return JSON.parse(JSON.stringify(this.$store.state.space?.videos || []))
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject);
    },
    disableList() {
      return this.projectFeatures?.disableMyList;
    },
    visibleLeasedSpaces() {
      return this.projectFeatures?.visibleLeasedSpaces;
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people'];
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    sliderZoomValue() {
      return this.generalConfig.modelDisplaySettings[this.activeProject].floor?.sliderZoomValue || 0
    }
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currentSpace: function (value, old) {
      if (!value.matterport_url) {
        this.activeTab = 'floor-view'
        if (!this.spaceFloor?.floor_plan_url) {
          if (this.videos.length > 0) {
            this.activeTab = 'videos-view'
          }
          if (this.photos.length > 0) {
            this.activeTab = 'images-view'
          }
        }
      }
    },
    viewMode(value, old) {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
      if (value !== old) {
        switch (value) {
          case this.viewModes.FIRST_PERSON:
            this.$refs.buttonVt.querySelector('span').classList.add('active')
            getSiblings(this.$refs.buttonVt).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
            })
            if (this.$refs.buttonG) {
              this.$refs.buttonG.querySelector('span').classList.add('active')
              getSiblings(this.$refs.buttonG).forEach((sibling) => {
                sibling.querySelector('span').classList.remove('active')
              })
              this.changeLevel('G', event)
            }
            if (this.$refs.sliderComponent) {
              this.$refs.sliderComponent.classList.remove('active')
            }
            if (this.$refs.levelComponent) {
              this.$refs.levelComponent.classList.add('active')
            }
            break
          case this.viewModes.TWO_DIMENSIONAL:
            this.$refs.button2d.querySelector('span').classList.add('active')
            getSiblings(this.$refs.button2d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
              if (this.$refs.sliderComponent) {
                this.$refs.sliderComponent.classList.add('active')
              }
              if (this.$refs.levelComponent) {
                this.$refs.levelComponent.classList.remove('active')
              }
            })
            break
          case this.viewModes.DEFAULT:
            this.$refs.button3d.querySelector('span').classList.add('active')
            getSiblings(this.$refs.button3d).forEach((sibling) => {
              sibling.querySelector('span').classList.remove('active')
              if (this.$refs.sliderComponent) {
                this.$refs.sliderComponent.classList.add('active')
              }
              if (this.$refs.levelComponent) {
                this.$refs.levelComponent.classList.remove('active')
              }
            })
            break
        }
      }
    },
    isSpaceSelectionOpen(value) {
      if (value) {
        this.$refs.listNode.classList.add('active')
        this.$refs.hideText.style.display = 'block'
        this.$refs.requestOfferText.style.display = 'none'
      } else {
        this.$refs.listNode.classList.remove('active')
        this.$refs.requestOfferText.style.display = 'block'
        this.$refs.hideText.style.display = 'none'
      }
    }
  },
  async beforeMount() {
    const spaceId = this.$route.params.spaceId
    if (!spaceId) return
    await this.$store.dispatch(
      spaceConstants.withNamespace(spaceConstants.action.LOAD_SPACE_DETAILS),
      spaceId
    )
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'building/setFitoutData') {
        const fitoutData = state.building.fitoutData
        if (fitoutData && fitoutData.data && Array.isArray(fitoutData.data)) {
          this.fitoutData = fitoutData.data // this will be an array of objects of type {text, value} from buildingStore module, where value is the id of the required fitout
        }
      }
    })

    this.$nextTick(() => {
      this.setSliderValue(this.sliderZoomValue);
    });
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async download(url, filename) {
      const { result, error } = await this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.DOWNLOAD_FILE),
        {
          id: this.currentSpace.id,
          project_slug: this.activeProject.slug
        }
      )

      if (error) {
        console.log(error)
      } else {
        const link = document.createElement('a')
        link.setAttribute('id', 'download-link')
        link.setAttribute('href', result)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    exploreSpace(spaceCode, buildingCode, floorCode, floorTypeCode, space) {
      this.$router.push({
        path: `/project/${this.activeProject}/space/${space}`
      })
    },
    getCombinedSpace(id) {
      if (!this.spaces) {
        return {
          floor: {}
        }
      }
      const foundSpace = this.spaces.find((s) => s.id === id)
      if (!foundSpace) {
        return null
      }
      foundSpace.floor = this.floors.find((f) => f.id === foundSpace.floor_id)
      return foundSpace
    },
    changeFitout(value) {
      const manager = this.engine3d.getClientManager()
      if (manager) {
        // TO DO - use proper FitoutItem
        const fitoutItem = {
          id: value
        };
        manager.changeFitout(fitoutItem)
        if (window && 'analyticsLayer' in window) {
          window.analyticsLayer.send({
            event: 'fitout_change',
            payload: {
              fitout: value
            }
          })
        }
      }
    },
    changeViewMode2D() {
      if (this.viewMode === FloorViewMode.TWO_DIMENSIONAL) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: '2d'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.TWO_DIMENSIONAL)
      const manager = this.engine3d.getClientManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.TWO_DIMENSIONAL);
        this.setSliderValue(this.sliderZoomValue);
      }
    },
    changeViewMode3D() {
      if (this.viewMode === FloorViewMode.DEFAULT) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: '3d'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.DEFAULT)
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.switchCamera(FloorViewMode.DEFAULT);
        this.setSliderValue(this.sliderZoomValue);
      }
    },
    changeViewModeFirstPerson() {
      if (this.viewMode === FloorViewMode.FIRST_PERSON) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: 'VT'
          }
        })
      }
      this.$store.dispatch('building/changeViewMode', FloorViewMode.FIRST_PERSON)
      const manager = this.engine3d.getClientManager()
      if (manager) {
        manager.switchCamera(FloorViewMode.FIRST_PERSON)
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeLevel(level, event) {
      if (!event.target.classList.contains('icon-street-view')) {
        event.target
          .closest('#btn-left-container')
          .querySelectorAll('.active')
          .forEach((item) => {
            item.classList.remove('active')
          })
        event.target.classList.add('active')
      }
    },

    handleDrag(e) {
      if (e.target.id !== 'dragger') {
        return
      }
      const parentRect = e.target.parentElement.getBoundingClientRect()
      const zoomRect = e.target.getBoundingClientRect()
      const absoluteYPositionWithinContainer = e.pageY - window.pageYOffset
      const cursorVal =
        e.pageY !== 0
          ? parentRect.bottom - absoluteYPositionWithinContainer - zoomRect.height / 2
          : 0
      if (
        cursorVal > 0 &&
        cursorVal <= this.zoomSettings.maxValue - zoomRect.height / 2 &&
        cursorVal >= this.zoomSettings.minValue
      ) {
        this.zoomSettings.zoomValue = cursorVal
        this.handleZoom();
      }
    },
    handleZoom(zoomCamera = true) {
      if (!this.$refs.dragger) return;
      const percentVal = (this.zoomSettings.zoomValue * 100) / this.zoomSettings.maxValue
      if(typeof this.$refs.dragger !== 'undefined') {
        this.$refs.dragger.style.bottom = percentVal > 5 ? `${percentVal}%` : `${0}%`
      }
      const manager = this.engine3d.getClientManager()
      if (manager && zoomCamera) {
        manager.setZoomValue(this.zoomSettings.maxValue - this.zoomSettings.zoomValue)
      }
    },
    setSliderValue(value) {
      this.zoomSettings.zoomValue = value;
      this.handleZoom(false);
    },
    handleDragStart(e) {
      if (e.target.id !== 'dragger') {
        return
      }
      e.dataTransfer.setDragImage(e.target, window.outerWidth + 100, window.outerHeight + 100)
    },
    increaseZoomValue() {
      if (this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 5) {
        this.zoomSettings.zoomValue < this.zoomSettings.maxValue - 10
          ? (this.zoomSettings.zoomValue += 5)
          : (this.zoomSettings.zoomValue = this.zoomSettings.maxValue - 5)
        this.handleZoom()
      }
    },
    decreaseZoomValue() {
      if (this.zoomSettings.zoomValue > this.zoomSettings.minValue) {
        this.zoomSettings.zoomValue > 5
          ? (this.zoomSettings.zoomValue -= 5)
          : (this.zoomSettings.zoomValue = 0)
        this.handleZoom()
      }
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    toggleSpaceSelectionDetails() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'toggle_space_cart_list',
          payload: {
            opened: !this.isSpaceSelectionOpen
          }
        })
      }
      this.isSpaceSelectionOpen = !this.isSpaceSelectionOpen
      this.showCartSpacesMobile = !this.showCartSpacesMobile
    },
    closeSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = false
      this.showCartSpacesMobile = false
    },
    addSpaceToCartAndOpenRequestOffer() {
      const space = {
        ...this.currentSpace,
        building: this.spaceBuilding,
        floor: this.spaceFloor
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'space'
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        space
      )
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    addCurrentSpaceToCart() {
      const space = {
        ...this.currentSpace,
        building: this.spaceBuilding,
        floor: this.spaceFloor
      }
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'add_space_to_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        space
      )
    },
    removeSpaceFromCart(space) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'remove_space_from_list',
          payload: {
            zone: 'space',
            space
          }
        })
      }
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },

    handleTabChange(tabId) {
      this.activeTab = tabId
    }
  }
}
